<template>
  <div class="container" fluid="lg">
    <b-form @submit.prevent="loginWithOTP">
      <div class="row justify-content-center">
        <div class="col-12 col-md-6 d-flex flex-column vh-100">
          <div class="row">
            <div class="col-12 pt-4">
              <h6 class="text-center">{{ $t('verify_otpcode') }}</h6>
            </div>

            <div class="col-12 pt-4 px-0 d-flex justify-content-center">
              <div style="display: flex">
                <OtpInput
                  ref="otpInput"
                  input-type="number"
                  input-classes="otp-input"
                  separator
                  :num-inputs="6"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  :class="{ error: $v.otpcode.$error }"
                  @on-change="handleOnChange"
                  @on-complete="handleOnComplete"
                />
              </div>
            </div>

            <div class="col-12 pt-5">
              <div class="small text-center text-primary">
                {{ $t('otpcode_content') }}
              </div>
              <h6 v-if="isNotProduction" class="text-success">OTP : {{ $store.getters['register/otp'] }}</h6>
            </div>

            <div class="col-12 p-4">
              <div class="d-flex justify-content-between">
                <div class="small" v-html="$t('expire_otpcode', { time: min + ':' + sec })"></div>
                <h6
                  :class="countdownseconds === 0 ? 'text-primary' : 'text-dark opacity-4'"
                  class="text-center pl-4 cursor-pointer"
                  @click="getOTP()"
                  v-html="$t('resend_otpcode')"
                ></h6>
              </div>
            </div>
          </div>

          <div class="row mt-auto mx-auto">
            <div v-show="routeName != 'forget-password-otp'" class="col-12 text-center">
              <router-link to="/register">
                <b-button pill variant="transparent px-4">
                  <div class="text-primary fw-bold">
                    {{ $t('change_phone_number') }}
                  </div>
                </b-button>
              </router-link>
            </div>

            <div class="col-12 py-4 text-center">
              <div class="d-flex justify-content-around">
                <router-link v-show="routeName != 'forget-password-otp'" to="/register">
                  <b-button pill variant="transparent px-4">
                    <div class="text-primary fw-bold">{{ $t('previous') }}</div>
                  </b-button>
                </router-link>

                <b-button type="submit" pill variant="primary px-4">{{ $t('verify_otpcode') }}</b-button>
              </div>
            </div>
          </div>

          <loading :is-busy="isBusy"></loading>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import AccountService from '@/api/service/AccountService'
import NotificationService from '@/api/service/NotificationService'
import { minLength, required } from 'vuelidate/lib/validators'
import OtpInput from '@bachdgvn/vue-otp-input'
import Loading from '@/components/Loading.vue'
import Cookies from 'js-cookie'

export default {
  components: { OtpInput, Loading },
  data() {
    return {
      otpcode: null,
      routeName: this.$router.currentRoute.name,
      countdownseconds: 180,
      isBusy: false,
      onesignalUserId: null,
    }
  },
  computed: {
    min() {
      let m = Math.trunc(this.countdownseconds / 60) % 60
      return m > 9 ? m : '0' + m
    },
    sec() {
      let s = Math.trunc(this.countdownseconds) % 60
      return s > 9 ? s : '0' + s
    },
    isNotProduction() {
      return process.env.VUE_APP_ENVIROMENT == 'local' || process.env.VUE_APP_ENVIROMENT == 'staging'
    },
  },
  watch: {
    countdownseconds: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.countdownseconds = this.countdownseconds - 1
          }, 1000)
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  mounted() {
    this.$refs.otpInput.$el.children[0].setAttribute('autocomplete', 'new-password')
    const inputs = this.$refs.otpInput.$el.querySelectorAll('.otp-input')
    inputs.forEach((i) => i.setAttribute('autocomplete', 'new-password'))
    inputs.forEach((i) => i.setAttribute('inputmode', 'numeric'))
  },
  methods: {
    handleOnComplete(value) {
      console.log(value)
    },
    handleOnChange(value) {
      this.otpcode = value
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput()
    },
    async loginWithOTP() {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        this.isBusy = true
        try {
          const res = await AccountService.checkOTP({
            phone_number: this.$store.getters['register/phone'],
            code: this.otpcode,
            forget_password: this.routeName == 'forget-password-otp' ? true : null,
          })

          this.isBusy = false

          if (res.status == 200) {
            const loginResponse = res.data.data
            this.$store.dispatch('auth/setAuthToken', {
              authToken: loginResponse.token,
            })

            // this.storeOneSignalUserId()
            if (this.routeName == 'forget-password-otp') {
              this.$router.replace({ name: 'forget-password-set-pincode' })
            } else {
              this.$router.replace({ name: 'set-pincode' })
            }
          }
        } catch (err) {
          this.isBusy = false
          this.showAlert(err)
        }
        // try {
        //   const res = await AccountService.login({
        //     phone_number: this.$store.getters["register/phone"],
        //     type: "otpcode", // pincode | otpcode
        //     code: this.otpcode,
        //     forget_password: this.routeName == 'forget-password-otp' ? true : null
        //   });

        //   this.isBusy = false;

        //   if (res.status == 200) {

        //     const loginResponse = res.data.data;
        //     this.$store.dispatch("auth/setToken", {
        //       token: loginResponse.token,
        //     });
        //     this.$store.dispatch("auth/setUserId", {
        //       userId: loginResponse.id,
        //     });

        //     this.$store.dispatch("auth/setActive", {
        //       active: loginResponse.active,
        //     });
        //     this.$store.dispatch("auth/setLoanSubmitted", {
        //       loanSubmitted: loginResponse.loan_application_submitted,
        //     });
        //     this.$store.dispatch("auth/setLoanFormState", {
        //       loanFormState: loginResponse.loan_form_state,
        //     });

        //     this.storeOneSignalUserId()

        //     this.$router.push({ name: "set-pincode" });
        //   }
        // } catch (err) {
        //   this.isBusy = false;
        //   this.showAlert(err);
        // }
      }
    },
    async getOTP() {
      if (this.countdownseconds === 0) {
        this.isBusy = true
        try {
          const res = await AccountService.getOTP({
            phone_number: this.$store.getters['register/phone'],
          })

          this.isBusy = false

          if (res.status == 200) {
            //Clear otp code
            this.$refs.otpInput.clearInput()
            this.otpcode = ''
            this.countdownseconds = 180
            if (process.env.VUE_APP_ENVIROMENT === 'local' || process.env.VUE_APP_ENVIROMENT === 'staging') {
              this.$store.dispatch('register/saveOtpCode', {
                otpCode: res.data.data,
              })
            }
          }
        } catch (err) {
          this.isBusy = false
          this.showAlert(err)
        }
      }
    },
    async storeOneSignalUserId() {
      this.onesignalUserId = Cookies.get('onesignal_user_id')
      if (this.onesignalUserId) {
        await NotificationService.storeUserId({
          one_signal_user_id: this.onesignalUserId,
        })
      }
    },
  },
  validations: {
    otpcode: {
      required,
      minLength: minLength(6),
    },
  },
}
</script>

<style>
.opacity-4 {
  opacity: 0.4;
}
</style>
